.LandingView {
  text-align: center;

    &__container {
        display: flex;
        height: 100vh;
        width: 100vw;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__colorBox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        border-radius: 1.2rem;
        width: 67%;
        background-image: linear-gradient(to bottom right, #2E0099, #FFC2E8);
    }

    &__whiteBox {
        height: calc(100% - 1rem);
        width: calc(100% - 1rem);
        background-color: white;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        color: #100018;
        font-family: 'Serif Display';
        font-size: 6rem;
        font-weight: 400;
        margin: 0;
        line-height: 5.5rem;
    }

    &__subtitle {
        color: #100018;
        font-family: 'Poiret One';
        font-size: 2rem;
        margin: 0rem;
        letter-spacing: .1em;
    }
}

@font-face {
    font-family: 'libreBarcode';
    src: url('../../../src/fonts/LibreBarcode128Text-Regular.ttf');
    font-display: swap;
}





