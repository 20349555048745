.Contact {
  &__container {
    width: calc(100vw - 6rem);
    height: calc(100vh - 2rem);
    padding: 1rem 3rem;
  }

  &__title {
    color: #100018;
    margin: 0;
    font-family: 'Serif Display';
    font-size: 6rem;
    font-weight: 400;
  }

  &__buttonContainer {
    display: flex;
    justify-content: right;
  }

  &__formLayout {
    margin: 1rem 3rem 0 3rem;
    width: calc(100% - 8rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem
  }
}