.About {
    &__container {
        height: 100vh;
        width: 100vw;
        display: grid;
        background-color: white;
        grid-template-columns: 2fr 1fr 4rem;
        overflow: hidden;
        max-width: 100%;
    }

    &__professional {
        padding: 3rem 10rem 3rem 3rem;
        max-width: inherit;
    }

    &__title {
        color: #100018;
        font-family: 'Serif Display';
        font-size: 6rem;
        font-weight: 400;
        margin: 0,
    }

    &__skills {
        max-width: inherit;
        padding: 2rem;
    }

    &__blurb {
        color: #100018;
        font-size: 1.2rem;
        font-family: 'Lato Light';
    }

    &__skillsTitle {
        color: #100018;
        font-family: 'Poiret One';
        font-size: 4rem;
        text-align: right;
        margin: 0 0 2rem 0;
        width: 100%;
    }

    &__skillsGrid {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 3fr 2fr;
        justify-content: space-between;
    }

    &__skillName {
        color: #100018;
        font-family: 'Lato Light';
        font-size: 1.2rem;
        margin: 0;
    }

    &__skillLevel {
        display: flex;
        justify-self: right;
    }

    &__skillLevelItem0, &__skillLevelItem1, &__skillLevelItem2, &__skillLevelItem3, &__skillLevelItem4 {
        margin: .4rem;
        border-radius: .8rem;
        height: .8rem;
        width: .8rem;
    }


    &__skillLevelItem0 {
        background-image: linear-gradient(0.25turn, #2E0099, #510098);
    }

    &__skillLevelItem1 {
        background-image: linear-gradient(0.25turn, #510098, #7D31AC);
    }

    &__skillLevelItem2 {
        background-image: linear-gradient(0.25turn, #7D31AC, #A861C0);
    }

    &__skillLevelItem3 {
        background-image: linear-gradient(0.25turn, #A861C0, #D492D4);
    }

    &__skillLevelItem4{
        background-image: linear-gradient(0.25turn, #D492D4, #FFC2E8);
    }

}